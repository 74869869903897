<script setup lang="ts">
import type { DropdownItem } from '#ui/types';

// COMPOSABLES
const { isLoggedIn, data, logout } = useAuthUtils();
const { openLoginModal } = useModals();
const { tt } = useTypedI18n();
const accountMenuItems = useAccountMenuItems();
const { ui: appConfigUi } = useAppConfig();
const localePath = useLocalePath();

// DATA
const isDropdownOpen = ref(false);

const items = computed<DropdownItem[][]>(() => [
    ...insertIf(!!data.value?.email, [
        {
            label: data.value?.email ?? '',
            slot: 'account',
            disabled: true,
        },
    ]),
    [
        ...accountMenuItems.value.map(({ label, to }) => ({
            label,
            click: (): void => {
                navigateTo(localePath(to));
            },
        })),
    ],
    [
        {
            label: tt('common.logout'),
            slot: 'logout',
            click: logout,
        },
    ],
]);

const dropdownUi = {
    item: { disabled: 'cursor-text select-text opacity-100', padding: 'py-2' },
    width: 'w-64',
    rounded: 'rounded-xl',
    base: 'p-3',
};

const avatarUi = {
    size: { lg: 'h-11 w-11' },
    background: 'bg-white',
    wrapper: 'border border-neutral-300',
    icon: { base: 'text-black' },
};

watchEffect(() => {
    if (!isLoggedIn.value) isDropdownOpen.value = false;
});
</script>

<template>
    <div>
        <!-- BSD-684 Overlay to fix immediate close after opening issue -->
        <div v-if="isLoggedIn && isDropdownOpen" class="fixed inset-0 z-10" :class="appConfigUi.modal.overlay.background" />

        <UDropdown v-if="isLoggedIn" v-model:open="isDropdownOpen" :items="items" :ui="dropdownUi" :popper="{ placement: 'bottom-end' }">
            <UAvatar icon="i-mdi-account" size="lg" :ui="avatarUi" />

            <template #account="{ item }">
                <div class="min-w-0 text-left font-bold">
                    <p>{{ tt('common.yourAccount') }}:</p>
                    <p class="text-primary truncate">
                        {{ item.label }}
                    </p>
                </div>
            </template>

            <template #item="{ item }">
                <span class="truncate font-medium">{{ item.label }}</span>

                <UIcon name="i-mdi-chevron-right" class="ms-auto flex-shrink-0 text-2xl" />
            </template>

            <template #logout="{ item }">
                <div class="flex w-full justify-between">
                    <p class="truncate font-medium">{{ item.label }}</p>
                    <UIcon name="i-mdi-logout" class="ms-auto flex-shrink-0 text-xl" />
                </div>
            </template>
        </UDropdown>

        <UButton v-else :label="tt('common.loginSignup')" size="xl" @click="openLoginModal" />
    </div>
</template>
