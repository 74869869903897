<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

// COMPOSABLES
const { tt } = useTypedI18n();
const { isLoggedIn, data, logout } = useAuthUtils();
const { isMobile } = useResponsive();
const { navMenuItems } = useNavMenuItems();
const accountMenuItems = useAccountMenuItems();
const localePath = useLocalePath();
const { openLoginModal } = useModals();

// DATA
const openModel = defineModel<boolean>('open', { required: true });
const transitionClass = 'transition duration-300';
const closeTransition = ref<boolean>(true);

// WATCHERS
watch(isMobile, val => val || immediateClose());

// METHODS
const immediateClose = (): void => {
    closeTransition.value = false;
    openModel.value = false;
};

const resetCloseTransition = (): void => {
    closeTransition.value = true;
};

const onItemClick = (handler: VoidFunction): void => {
    immediateClose();
    handler();
};

const goTo = ({ to, isExternal }: { to: RouteLocationRaw; isExternal?: boolean }): void => {
    if (isExternal) {
        navigateTo(to, { external: true, open: { target: '_blank' } });
    } else {
        navigateTo(localePath(to));
    }
};
</script>

<template>
    <Transition
        enter-from-class="translate-x-[100%]"
        leave-to-class="translate-x-[100%]"
        :enter-active-class="transitionClass"
        :leave-active-class="closeTransition ? transitionClass : ''"
        @after-leave="resetCloseTransition"
    >
        <div v-if="openModel" class="fixed top-[--header-height] z-30 h-[calc(100vh-var(--header-height))] w-screen overflow-y-auto bg-white">
            <div class="divi min-h-full divide-y divide-neutral-300">
                <!-- Navigation Menu -->
                <div class="space-y-2 p-4">
                    <button
                        v-for="(item, i) in navMenuItems"
                        :key="i"
                        class="flex w-full items-center justify-between rounded-md p-2 text-left font-medium"
                        @click="onItemClick(() => goTo(item))"
                    >
                        {{ item.label }}
                        <UIcon class="text-2xl" name="i-mdi-chevron-right" />
                    </button>
                </div>

                <!-- Account Menu -->
                <div class="space-y-2 p-4">
                    <template v-if="isLoggedIn">
                        <div class="min-w-0 p-2 text-left font-bold">
                            <p>{{ tt('common.yourAccount') }}:</p>
                            <p class="text-primary truncate">
                                {{ data?.email }}
                            </p>
                        </div>

                        <button
                            v-for="(item, i) in accountMenuItems"
                            :key="i"
                            class="flex w-full items-center justify-between rounded-md p-2 text-left font-medium"
                            @click="onItemClick(() => goTo(item))"
                        >
                            {{ item.label }}
                            <UIcon class="text-2xl" name="i-mdi-chevron-right" />
                        </button>

                        <button class="flex w-full items-center justify-between rounded-md p-2 text-left font-medium" @click="onItemClick(logout)">
                            {{ tt('common.logout') }}
                            <UIcon class="text-xl" name="i-mdi-logout" />
                        </button>
                    </template>

                    <UButton v-else :label="tt('common.loginSignup')" size="xl" block @click="onItemClick(openLoginModal)" />
                </div>
            </div>
        </div>
    </Transition>
</template>
