<template>
    <div>
        <MainHeader />
        <div class="flex min-h-[calc(100vh-var(--header-height))] flex-col items-center">
            <main class="flex w-full flex-1 flex-col">
                <slot />
            </main>
            <MainFooter />
        </div>
    </div>
</template>
