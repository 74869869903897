<script setup lang="ts">
import { Locale } from '~/i18n/i18nConfig';

const { locale, setLocale } = useI18n();
const route = useRoute();
const getRouteBaseName = useRouteBaseName();
const { changeAlpLocale } = useAlpRouteUtils();

const options = [
    { locale: Locale.EN, label: 'English' },
    { locale: Locale.IT, label: 'Italiano' },
    { locale: Locale.DE, label: 'Deutsch' },
    { locale: Locale.ES, label: 'Español' },
    { locale: Locale.PL, label: 'Polski' },
];

const modelValue = computed(() => {
    return options.find(option => option.locale === locale.value);
});

const changeLocale = ({ locale }: { locale: Locale }): void => {
    if (getRouteBaseName(route) === 'auctions-localizedAssetType-optionalParams') {
        changeAlpLocale(locale);
    } else {
        setLocale(locale);
    }
};
</script>

<template>
    <USelectMenu v-slot="{ open }" :model-value="modelValue" :options="options" :ui-menu="{ width: 'min-w-28' }" @change="changeLocale">
        <UButton color="white" size="sm" :padded="false" variant="link">
            <UIcon name="i-mdi-world-wide-web" class="h-4 w-4" />
            <span class="text-xs leading-4">{{ modelValue!.label }}</span>
            <UIcon name="i-mdi-arrow-drop-down" class="h-4 w-4 transition-transform" :class="[open && 'rotate-180 transform']" />
        </UButton>
    </USelectMenu>
</template>
