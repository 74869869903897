<script setup lang="ts">
// COMPOSABLES
const { navMenuItems } = useNavMenuItems();

// DATA
const isMobileMenuOpen = ref(false);

// METHODS
const toggleMobileMenu = (): void => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
};
</script>

<template>
    <nav class="sticky inset-x-0 top-0 z-40 flex w-full items-center justify-center bg-white ring-1 ring-gray-300">
        <BaseWidthContainer as="header" class="relative z-40 flex h-[--header-height] flex-row flex-nowrap items-center justify-between gap-4">
            <div class="flex items-center gap-6">
                <NuxtLinkLocale to="index" @click="isMobileMenuOpen = false">
                    <SvgoFleequidLogo class="text-fl-primary h-[26px]" :font-controlled="false" />
                </NuxtLinkLocale>

                <!-- Desktop/Tablet -->
                <NuxtLinkLocale
                    v-for="item in navMenuItems"
                    :key="item.label"
                    class="hidden font-medium text-black hover:underline md:flex"
                    :to="item.to"
                    :target="item.isExternal ? '_blank' : undefined"
                >
                    {{ item.label }}
                </NuxtLinkLocale>
            </div>

            <!-- Desktop/Tablet -->
            <HeaderAccountMenu class="hidden md:block" />

            <!-- Mobile -->
            <div class="flex items-center md:hidden">
                <div class="flex translate-x-3 cursor-pointer items-center p-3 md:hidden" @click="toggleMobileMenu">
                    <UIcon :name="isMobileMenuOpen ? 'i-heroicons-x-mark-20-solid' : 'i-heroicons-bars-3-16-solid'" class="text-3xl" />
                </div>
            </div>
        </BaseWidthContainer>
    </nav>
    <MobileMenu v-model:open="isMobileMenuOpen" />
</template>
